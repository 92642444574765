<template>
  <div>
    <n-grid x-gap="12" :cols="2">
      <n-gi>
        <n-divider dashed>Guid生成器</n-divider>
        <el-row>
          <el-col :span="20">
            <n-input
              v-model:value="guidValue"
              placeholder=""
            />
          </el-col>
          <el-col :span="4"><n-button @click="changeGuid">点击生成</n-button></el-col>
        </el-row>
      </n-gi>

      <n-gi>
        <div>
          <n-divider dashed>Base64生成器</n-divider>
          <el-row>
            <el-col :span="4">
              <n-upload
                @change="handleChange"
                :default-upload="false"
                multiple
                :show-file-list="false"
                ref="upload"
              >
                <n-button>选择文件</n-button>
              </n-upload>
              <br>
              <el-image
                style="width: 100px; height: 100px"
                :src="base64Value"
                :preview-src-list="[base64Value]"
              >
              </el-image>
            </el-col>
            <el-col :span="20">
              <el-input
                type="textarea"
                style="max-height: 240px; min-height: 240px"
                :rows="7"
                placeholder=""
                v-model="base64Value"
              ></el-input>
            </el-col>
          </el-row>
          <div></div>
        </div>
      </n-gi>
    </n-grid>
  </div>
</template>

<script>
export default {
  methods: {
    handleChange(file) {
      let that = this;
      console.log(file);
      var reader = new FileReader();
      reader.readAsDataURL(file.file.file);
      reader.onload = function () {
        that.base64Value = reader.result;
      };
      console.log(file);
    },
    changeGuid(){
        this.guidValue = this.guid();
    },
    guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
  },
  data() {
    return {
      base64Value: "",
      guidValue : "",
    };
  },
};
</script>

<style>
.light-green {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.12);
}
.green {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.24);
}
</style>